import React from 'react';
import { Routes, Route } from 'react-router-dom';

const LayoutAdmin = React.lazy(() => import('../layouts/user'));
const Home = React.lazy(() => import('../pages/Home'));
const MeusAgendamentos = React.lazy(() => import('../pages/MeusAgendamentos'));
const Faturas = React.lazy(() => import('../pages/Faturas'));
const Profile = React.lazy(() => import('../pages/Profile'));

function AppUserRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LayoutAdmin />}>
        <Route path="/" element={<Home />} />
        <Route path="/meus-agendamentos" element={<MeusAgendamentos />} />
        <Route path="/faturas" element={<Faturas />} />
        <Route path="/perfil" element={<Profile />} />
      </Route>
    </Routes>
  );
}

export default AppUserRoutes;
