/* eslint-disable no-nested-ternary */
import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Spinner } from 'reactstrap';

import bgSplash from './app/assets/img/bg-splash.png';
import { useAuth } from './app/hooks/useAuth';
import AppRoutes from './app/routes/app.routes';
import AuthRoutes from './app/routes/auth.routes';

import 'react-pro-sidebar/dist/css/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './app/assets/vendor/fontawesome-free-5.15.2-web/css/all.min.css';
import './app/assets/vendor/fontawesome-free-6.2.0-web/css/all.min.css';
import './app/assets/css/bootstrap.css';
import './app/assets/css/custom.css';

const loading = (
  <div
    className="bg-primary opacity-7 d-flex w-100 vh-100 align-items-center justify-content-center position-fixed left-0 top-0"
    style={{ zIndex: 999999 }}
  >
    <Spinner type="grow" />
  </div>
);

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <Router>
      <Suspense fallback={loading}>
        <div className="mh-100 position-relative">
          <div
            className="bg-splash"
            style={{ backgroundImage: `url(${bgSplash})` }}
          >
            { isAuthenticated ? (
              <AppRoutes />
            ) : (
              <AuthRoutes />
            )}
          </div>
        </div>
      </Suspense>
    </Router>
  );
}

export default App;
