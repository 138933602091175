import React, {
  useState,
  useEffect,
  useRef,
  createContext,
  useMemo,
} from 'react';

import Notification from '../components/Notification';
import { ID_KEY, TOKEN_KEY, USER_NAME } from '../constants/auth';
import api from '../services/api';

export const AuthContext = createContext();

function AuthProvider({ children }) {
  const [loggedUser, setLoggedUser] = useState({});
  const [userToken, setUserToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const notificationRef = useRef();

  useEffect(() => {
    if (!userToken) {
      setIsAuthenticated(false);
      return;
    }

    (async () => {
      try {
        const email = localStorage.getItem(ID_KEY);
        const { data } = await api.get(encodeURI(`/getUsers?email=${email}`));
        if (data) {
          setIsAuthenticated(true);
        }
        setLoggedUser(data);
      } catch (error) {
        console.log(error);
        setIsAuthenticated(false);
        if (typeof error === 'string') {
          notificationRef.current.notify({
            message: error,
            color: 'danger',
          });
          return;
        }

        if (error && error.response) {
          const { message } = error.response.data;
          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });
          }
        }
      }
    })();
  }, [userToken]);

  const value = useMemo(
    () => ({
      isAuthenticated,
      loggedUser,
      setLoggedUser,
      signIn: (token, user) => {
        localStorage.setItem(TOKEN_KEY, token);
        localStorage.setItem(ID_KEY, user.email);
        localStorage.setItem(USER_NAME, user.name);
        setIsAuthenticated(true);
        setUserToken(token);
      },
      signOut: async () => {
        console.log('sign out');
        await api.delete('/logoutUsers');
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(ID_KEY);
        localStorage.removeItem(USER_NAME);
        setLoggedUser(null);
        setIsAuthenticated(false);
        setUserToken(null);
      },
    }),
    [userToken, isAuthenticated, loggedUser],
  );

  useEffect(() => {
    const tokenUser = localStorage.getItem(TOKEN_KEY);

    if (tokenUser) {
      setIsAuthenticated(true);
      setUserToken(tokenUser);
    }
  }, []);

  return (
    <AuthContext.Provider value={value}>
      <Notification ref={notificationRef} />
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
